import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as RequestQuery from 'graphql/request.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { defaultPaginationParams } from 'defaults/pagination';
import { showPlural } from 'utils';
import useActiveTab from 'hooks/useActiveTab';
import ErrorModal from 'components/ErrorModal';
import PageReport from 'components/PageReport';
import RequestSentMessage from 'components/RequestSentMessage';
import RequestsTable from 'components/RequestsTable';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import PageResponses from 'pages/PageResponses';
import { PageResponses as PageResponsesType } from 'api/data/response/types';
import { PageRequests as PageRequestsType } from 'api/data/request/types';
import './style.scss';

export type PageParams = { id: string; tab?: string; subTab?: string };

const RequestsAndSubmissions: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { data: responsesData, refetch: refetchPageResponses } = useQuery<PageResponsesType>(
    ResponseQuery.PageResponses,
    {
      variables: { pageId: params.id, pagination: defaultPaginationParams },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: requestsData, loading: requestLoading } = useQuery<PageRequestsType>(RequestQuery.PageRequests, {
    variables: { pageId: responsesData?.pageResponses.page.id, status: ['SENT', 'FAILED', 'OVERDUE'] },
    fetchPolicy: 'network-only',
    skip: !responsesData?.pageResponses.page.id,
  });

  const [, setLocation] = useLocation();

  const [tabContentKey, setTabContentKey] = useState('');
  const [loadingResponses, setLoadingResponses] = useState(false);
  const spaceSlug = currentSpaceSlug();

  useEffect(() => {
    if (tabContentKey && spaceSlug) setLocation(`/${spaceSlug}/pages/${params.id}/${params.tab}/${tabContentKey}`);
  }, [params, tabContentKey, spaceSlug, setLocation]);

  useActiveTab(
    setTabContentKey,
    requestsData?.pageRequests,
    responsesData?.pageResponses.responses.data,
    params?.subTab,
  );

  const handleResponsesPaginationChange = async (offset: number, limit: number) => {
    setLoadingResponses(true);
    await refetchPageResponses({ pageId: params.id, pagination: { offset, limit }, currentSpace: spaceSlug });
    setLoadingResponses(false);
  };

  if (!responsesData || !requestsData) return null;

  const {
    pageResponses: { page, responses },
  } = responsesData;

  const { pageRequests: requests } = requestsData;

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    submissions: (
      <PageResponses
        responses={responses.data}
        page={page}
        totalCollectedCents={page.totalCollectedCents}
        refetch={handleResponsesPaginationChange}
        loading={loadingResponses}
        paginationInfo={responses.paginationInfo}
      />
    ),
    requests: <RequestsTable requests={requests} pageId={page.id} loading={requestLoading} />,
    reporting: <PageReport pageId={page.id} tabParam={params?.subTab} />,
  };

  return (
    <div className="requests-submissions">
      {tabContentKey ? (
        <Tabs activeKey={tabContentKey}>
          {requests.length > 0 && (
            <Tab
              label={`${requests.length} request${showPlural(requests.length)}`}
              tabKey="requests"
              onClick={setTabContentKey}
            />
          )}
          <Tab
            label={`${responses.paginationInfo.totalCount} submission${showPlural(responses.paginationInfo.totalCount)}`}
            tabKey="submissions"
            onClick={setTabContentKey}
          />
          <Tab label="Reporting" tabKey="reporting" onClick={setTabContentKey} />
        </Tabs>
      ) : (
        <div></div>
      )}
      <RequestSentMessage showMessage={!!responsesData && !!requestsData} />
      <ErrorModal />
      {tabContent[tabContentKey]}
    </div>
  );
};

export default RequestsAndSubmissions;
