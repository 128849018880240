import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { useRoute, Link, useLocation } from 'wouter';
import * as PayoutsQuery from 'graphql/payout.graphql';
import { currencyFormatter, extractQueryStringFromParams } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import ErrorMessage from 'components/ErrorMessage';
import ICONS from 'components/Icons';
import { mapStatusPayouts, totalAmountCentsTooltip } from '../';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Message from 'components/Message';
import Summary from './Summary';
import Tag from 'components/Tag';
import Timeline, { TimelineSteps } from 'components/Timeline';
import { PayoutDetails } from 'api/data/payout/types';
import './style.scss';

const formatDate = (date?: string) => (date ? dayjs(date).format('MMM DD, YYYY') : '-');

const mapToTimeline = (payout: PayoutDetails) => {
  return [
    {
      title: 'Payout expected to arrive at account',
      subTitle: formatDate(payout.arrivalDate),
      status: payout.status === 'PAID' ? 'completed' : 'upcoming',
    },
    {
      title: 'Payout in transit',
      subTitle: formatDate(payout.insertedAt),
      status: payout.status === 'PAID' ? 'completed' : 'current',
    },
    {
      title: 'Payout initiated',
      subTitle: formatDate(payout.insertedAt),
      status: 'completed',
    },
  ] as TimelineSteps[];
};

const DetailsItem = ({ label, data, className }: { label: string; data?: string; className?: string }) => {
  return (
    <div className={className}>
      <p className="subtitle-x-small">{label}</p>
      <p className="paragraph-x-small">{data}</p>
    </div>
  );
};

const PayoutsDetail = () => {
  const [, params] = useRoute('/:currentSpace/payouts/:id');
  const {
    loading: loading,
    data,
    error,
  } = useQuery<{ payout: PayoutDetails }>(PayoutsQuery.GetPayout, {
    variables: {
      payoutId: params?.id,
    },
  });
  const { currentOrg } = useCurrentOrganization();
  const [showMessage, setShowMessage] = useState(false);
  const [location, setLocation] = useLocation();

  const payout = data?.payout;

  const payoutStatus = mapStatusPayouts[payout?.status || ''];
  const steps = payout ? mapToTimeline(payout) : [];

  useEffect(() => {
    if (extractQueryStringFromParams('initiated')) {
      setShowMessage(true);
      setLocation(location);
    }
  }, [location, setLocation]);

  return (
    <>
      <Message type="succeeded" showMessage={showMessage} setShowMessage={setShowMessage}>
        Payout successfully initiated
      </Message>
      <LoggedPageContainer
        className="payouts payouts-details"
        organizationStatus={currentOrg?.status}
        headerChildren={
          <div className="row align-center second-header">
            <Link className="row align-center subtitle-x-small" to={`/${params?.currentSpace}/payouts`}>
              {ICONS['wallet']} Payouts
            </Link>
            <span className="row align-center subtitle-x-small arrow-right">
              {ICONS['arrow_right']} {formatDate(payout?.insertedAt)}
            </span>
          </div>
        }>
        {loading && <p>Loading...</p>}

        {!loading && error && <ErrorMessage message="Something went wrong, please reload the page" />}

        {!loading && !error && (
          <div className="max-width-desktop">
            <div className="payout-header">
              <p className="subtitle-xx-small">Payout</p>
              <span className="row heading-small">
                {totalAmountCentsTooltip(payout?.totalAmountCents || 0)}
                <Tag type={payoutStatus.class} label={payoutStatus.label} size="large" />
              </span>
            </div>
            <div className="row flex-wrap">
              <section className="row flex-1 direction-column payout-timeline">
                <p className="subtitle-medium">Timeline</p>
                {steps.length > 0 && <Timeline steps={steps} />}
              </section>
              <section className="row flex-1 direction-column details">
                <p className="subtitle-medium">Details</p>
                <div className="row flex-wrap">
                  <DetailsItem label="To the order of" data={payout?.details?.payableTo} />
                  <DetailsItem label="Amount" data={currencyFormatter(payout?.totalAmountCents)} />
                  <DetailsItem
                    label="Initiated at"
                    data={dayjs(payout?.details?.initiatedAt).format('MMM D, YYYY h:mma')}
                  />
                  <DetailsItem label="Initiated by" data={payout?.details?.initiatedBy} />
                  <DetailsItem label="Recipient" data={payout?.details?.recipient} />
                  <DetailsItem label="Delivery method" data={payout?.details?.deliveryMethod} />
                  <DetailsItem label="Deposited" data={payout?.details?.deposited} />
                  <DetailsItem label="Deposited at" data={formatDate(payout?.details?.depositedAt)} />
                  <DetailsItem label="Memo" data={payout?.details?.memo} className="entire" />
                </div>
              </section>
            </div>
            <div>
              <Summary data={payout?.spaces || {}} />
            </div>
          </div>
        )}
      </LoggedPageContainer>
    </>
  );
};

export default PayoutsDetail;
