import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { RouteComponentProps, useLocation } from 'wouter';
import { useQuery, useMutation } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as PageQuery from 'graphql/page.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { PagesStatus } from 'defaults/page';
import { defaultPaginationParams } from 'defaults/pagination';
import { isKYCd } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import useToggle from 'hooks/useToggle';
import Badge from 'components/Badge';
import CopyPageModal from 'components/CopyPageModal';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import Edit from './Edit';
import ErrorModal from 'components/ErrorModal';
import EyeButton from 'components/Button/EyeButton';
import Header from 'pages/MyPage/Header';
import LoggedPageContainer from 'components/LoggedPageContainer';
import PageSettings from 'components/PageSettings';
import ProtectedComponent from 'components/ProtectedComponent';
import RequestsAndSubmissions from './RequestsAndSubmissions';
import ShareButton from 'components/ShareButton';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import Team from 'components/Team';
import { PageResponses as PageResponsesType } from 'api/data/response/types';
import { PageDataDraftBlock, PageView } from 'api/data/pages/types';
import './style.scss';

export type PageParams = { id: string; tab?: string; subTab?: string };

interface HeaderTabsProps {
  tabContentKey: string;
  goToTab: (tab: string) => void;
  pageStatus: string;
  className?: string;
}

const MyPage: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { data: responsesData, refetch } = useQuery<PageResponsesType>(ResponseQuery.PageResponses, {
    variables: { pageId: params.id, pagination: defaultPaginationParams },
    fetchPolicy: 'cache-and-network',
  });
  const { data: pageData } = useQuery<PageView>(PageQuery.GetPageSettings, {
    variables: { id: params.id },
    fetchPolicy: 'network-only',
  });
  const [tabContentKey, setTabContentKey] = useState(params?.tab || 'activity');

  const [updatePage] = useMutation(PageQuery.SaveSettings, {
    refetchQueries: [{ query: PageQuery.GetParticipants, variables: { pageId: params.id, perspective: 'organizer' } }],
  });
  const [updateStatusMutation, { loading: updateLoading }] = useMutation(PageQuery.UpdatePageStatus);
  const [showCopyPageModal, setShowCopyPageModal] = useState(false);
  const [pageTitle, setPageTitle] = useState(responsesData?.pageResponses.page.title || '');
  const refOutside = useRef<HTMLElement>(null);
  const [isSettingsOpen, setToggleSettings] = useToggle();
  const [, setLocation] = useLocation();

  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  const handleOnClickStatus = async (status: PagesStatus) => {
    const updatePageStatus = { id: page.id, status };
    if (tabContentKey === 'edit') {
      setTabContentKey('activity');
      setLocation(`/${currentSpaceSlug()}/pages/${page.slug}/activity`);
    }
    await updateStatusMutation({ variables: updatePageStatus });
    await refetch();
  };

  const handleSettingsChange = (changes: Partial<PageDataDraftBlock>) => {
    const page = { ...changes, id: pageData?.page.id };
    void updatePage({
      variables: { page },
      optimisticResponse: {
        savePage: {
          ...pageData?.page,
          ...changes,
        },
      },
    });
  };

  if (!responsesData) return null;

  const {
    pageResponses: { page },
  } = responsesData;

  const spaceSlug = currentSpaceSlug() || '';

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    edit: <Edit pageId={params.id} setPageTitle={setPageTitle} setTabContentKey={setTabContentKey} />,
    activity: <RequestsAndSubmissions params={{ ...params, tab: params.tab || 'activity' }} />,
    team: <Team page={pageData?.page} />,
  };

  const goToTab = (tab: string) => {
    setTabContentKey(tab);
    setLocation(`/${spaceSlug}/pages/${page.slug}/${tab}`);
  };

  return (
    <>
      <LoggedPageContainer
        className="my-page-default my-page"
        showSideBar={false}
        header={
          <Header
            title={pageTitle || page.title}
            tabs={
              <div>
                <HeaderTabs
                  tabContentKey={tabContentKey}
                  goToTab={goToTab}
                  pageStatus={page.status}
                  className="desktop-only"
                />
              </div>
            }
            linkBack={`/${spaceSlug}/pages`}
            actions={
              page.status === 'archived' ? (
                <button onClick={() => void handleOnClickStatus(PagesStatus.ACTIVE)} disabled={updateLoading}>
                  Restore
                </button>
              ) : (
                <div className="row actions">
                  <EyeButton url={`/${page.slug}`} desktopOnly />
                  {page.status === 'archived' ? null : (
                    <DropDown outsideRef={refOutside} openedIcon="menu" closedIcon="menu_open">
                      <DropDownItem className="mobile-only" onClick={() => setLocation(`/${page.slug}`)} icon="eye">
                        View
                      </DropDownItem>
                      <ProtectedComponent
                        action="PAGE_SETTINGS"
                        currentUserOrganizationRole={currentOrg?.currentUserRole}
                        currentUserSpaceRole={currentSpace?.currentUserRole}>
                        <DropDownItem icon="settings" onClick={() => setToggleSettings(true)}>
                          Settings
                        </DropDownItem>
                      </ProtectedComponent>
                      <DropDownItem onClick={() => void setShowCopyPageModal(true)} icon="copy">
                        Copy to...
                      </DropDownItem>
                      <DropDownItem icon="archive" onClick={() => void handleOnClickStatus(PagesStatus.ARCHIVED)}>
                        Archive
                      </DropDownItem>
                    </DropDown>
                  )}
                  <ShareButton organizationId={page.organizationId} organizationStatus={page.organization?.status} />
                </div>
              )
            }
            additionalActions={
              <>
                {page.status === 'archived' && (
                  <p className="archived-text">
                    This page is archived.{' '}
                    <button
                      className="button-link"
                      onClick={() => void handleOnClickStatus(PagesStatus.ACTIVE)}
                      disabled={updateLoading}>
                      Restore
                    </button>
                    .
                  </p>
                )}
                <CopyPageModal pageId={page.id} showModal={showCopyPageModal} setShowModal={setShowCopyPageModal} />
              </>
            }
            tabsOnMobile={
              <HeaderTabs
                tabContentKey={tabContentKey}
                goToTab={goToTab}
                pageStatus={page.status}
                className="mobile-only"
              />
            }
          />
        }
        organizationStatus={page.organization.status}>
        <ErrorModal />
        <div
          className={clsx({
            'banner-space': !!page.organization.status && !isKYCd(page.organization.status),
          })}>
          {tabContent[tabContentKey]}
        </div>
      </LoggedPageContainer>
      {pageData?.page && (
        <PageSettings
          page={pageData.page}
          toggleSettings={setToggleSettings}
          isSettingsOpen={isSettingsOpen}
          handleConfigChanges={handleSettingsChange}
        />
      )}
    </>
  );
};

export default MyPage;

const HeaderTabs = ({ tabContentKey, goToTab, pageStatus, className }: HeaderTabsProps) => {
  return tabContentKey ? (
    <Tabs className={clsx('header-tabs', className)} activeKey={tabContentKey}>
      {pageStatus !== 'archived' && <Tab label="Edit" tabKey="edit" onClick={goToTab} />}
      <Tab label="Activity" tabKey="activity" onClick={goToTab} />
      <Tab label="Team" tabKey="team" onClick={goToTab} suffix={<Badge label="Beta" />} />
    </Tabs>
  ) : null;
};
