import React, { Fragment, FC } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useRoute } from 'wouter';
import * as OrganizationQuery from 'graphql/organization.graphql';
import * as PayoutsQuery from 'graphql/payout.graphql';
import { currencyFormatter } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Alert from 'components/Alert';
import Balance from './Balance';
import BlankState from 'components/BlankState';
import ErrorModal from 'components/ErrorModal';
import ICONS from 'components/Icons';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Tag, { TagType } from 'components/Tag';
import Tooltip from 'components/Tooltip';
import { PayoutsBalanceByOrganization, PayoutsByOrganization } from 'api/data/payout/types';
import { StripeKYCForOrganization } from 'api/data/user/types';
import payoutsEmptyState from 'assets/payouts_empty_state.png';
import './style.scss';

export const mapStatusPayouts: { [key: string]: { class: TagType; label: string } } = {
  INITIATED: { class: 'new', label: 'Initiated' },
  IN_TRANSIT: { class: 'pending', label: 'On the way' },
  PAID: { class: 'positive', label: 'Paid' },
  FAILED: { class: 'negative', label: 'Failed' },
  PENDING: { class: 'pending', label: 'Pending' },
};

export const totalAmountCentsTooltip = (amount: number) => (
  <Tooltip
    title={amount < 0 ? 'Your account is debited when refund(s) exceeded your balance.' : ''}
    color="dark"
    className="amount-tooltip">
    {currencyFormatter(amount)}
  </Tooltip>
);

interface PayoutButtonProps {
  accountRestricted: boolean;
  hasAvailableAmountCents: boolean;
  availableToPayOut: boolean;
  handleOnClick: () => void;
  label: string;
}

export const PayoutButton: FC<PayoutButtonProps> = ({
  accountRestricted,
  hasAvailableAmountCents,
  availableToPayOut,
  handleOnClick,
  label,
}) => {
  const payoutBtnTooltipTitle = () => {
    if (accountRestricted) return 'Payouts have been paused';
    if (!hasAvailableAmountCents) return 'Available balance must be greater than $0';
    return '';
  };

  return (
    <Tooltip title={payoutBtnTooltipTitle()} color="dark" className="payout-btn-tooltip">
      <button className="primary button-size-sm" onClick={handleOnClick} disabled={!availableToPayOut}>
        {label}
      </button>
    </Tooltip>
  );
};

const Payouts = () => {
  const { currentOrg } = useCurrentOrganization();
  const [location, setLocation] = useLocation();
  const [, params] = useRoute('/:currentSpace/payouts/:id');

  const [updateUrl, { loading: loadingUpdateUrl }] = useMutation<StripeKYCForOrganization>(
    OrganizationQuery.LinkAccount,
  );
  const { loading: loadingPayouts, data: payoutsData } = useQuery<PayoutsByOrganization>(
    PayoutsQuery.PayoutsByOrganization,
    {
      variables: {
        organizationId: currentOrg?.id,
      },
      skip: !currentOrg?.id,
      fetchPolicy: 'network-only',
    },
  );

  const { loading: loadingBalance, data: balanceData } = useQuery<PayoutsBalanceByOrganization>(
    PayoutsQuery.PayoutsBalanceByOrganization,
    {
      variables: {
        organizationId: currentOrg?.id,
      },
      skip: !currentOrg?.id,
      fetchPolicy: 'network-only',
    },
  );

  const balance = balanceData?.payoutsBalanceByOrganization;

  const formatSpaces = (spaces: string[]) => {
    if (spaces.length <= 3) return spaces.join(', ');

    const remainingCount = spaces.length - 2;
    return `${spaces[0]}, ${spaces[1]} and ${remainingCount} more clubs`;
  };

  const handleOnClickKYC = async () => {
    const { data } = await updateUrl({
      variables: {
        success_path: location,
        failure_path: `/${params?.currentSpace || ''}/payouts#error`,
      },
    });

    const url = data?.createAccountLink.url;
    url && window.open(url, '_blank');
  };

  const payouts = payoutsData?.payoutsByOrganization;
  const hasPayouts = !!payouts && payouts?.length > 0;
  const accountRestricted = currentOrg?.status === 'RESTRICTED' && currentOrg.provider === 'stripe' && hasPayouts;
  const hasAvailableAmountCents = !!balance?.availableAmountCents && balance?.availableAmountCents > 0;
  const availableToPayOut = !accountRestricted && !!hasAvailableAmountCents;

  return (
    <LoggedPageContainer
      className="payouts"
      organizationStatus={currentOrg?.status}
      headerChildren={
        <div className="row align-center space-between second-header payout-main-header">
          <div>
            {ICONS['wallet']} <span className="subtitle-x-small">Payouts</span>
          </div>
          {currentOrg?.provider !== 'finix' && (
            <PayoutButton
              accountRestricted={accountRestricted}
              hasAvailableAmountCents={hasAvailableAmountCents}
              availableToPayOut={availableToPayOut}
              handleOnClick={() => setLocation('payouts/available-to-pay-out')}
              label="Pay out"
            />
          )}
        </div>
      }>
      <div className="max-width-desktop">
        {accountRestricted && (
          <Alert
            type="pending"
            content={
              <span className="paragraph-xx-small">
                Payouts have been paused. In order to remediate this issue, {currentOrg.contactName}, who is the owner
                of the associated Stripe account, must provide Stripe with the additional information that they require.
                Contact <a href="mailto:help@omella.com">help@omella.com</a> with questions.
              </span>
            }
            title={
              <>
                Action Required{' '}
                <Tooltip
                  title="One of our core values is saving you time, so we do apologize for this inconvenience. Unfortunately, Omella is unable to resolve this on your behalf (as much as we wish we could!), as it is a security measure required by our payment processor to ensure that accounts are in good standing"
                  color="dark"
                  className="row align-center tooltip-alert">
                  {ICONS['solid_help']}
                </Tooltip>
              </>
            }
            footer={
              <button
                disabled={loadingUpdateUrl}
                className="button-link link-small underline"
                onClick={() => void handleOnClickKYC()}>
                {loadingUpdateUrl ? 'Loading...' : 'Provide required information on Stripe'}
              </button>
            }
          />
        )}
        <Balance
          balance={balance}
          loading={loadingBalance}
          availableToPayout={availableToPayOut && currentOrg?.provider !== 'finix'}
        />
        <p className="subtitle-small">All Payouts</p>
        {loadingPayouts && <p>Loading...</p>}
        {!loadingPayouts && hasPayouts && (
          <table className="table list">
            <tbody>
              {payouts.map(payout => {
                const payoutStatus = mapStatusPayouts[payout.status];
                const lastRow = payout === payouts[payouts.length - 1];

                return (
                  <Fragment key={payout.id}>
                    <tr
                      className={clsx('mobile-only', { 'last-row': lastRow })}
                      onClick={() => setLocation(`payouts/${payout.slug}`)}>
                      <td className="date-spaces row direction-column">
                        <div>
                          <p className="date subtitle-x-small row-inline">
                            {dayjs(payout.insertedAt).format('MMM DD, YYYY')}
                          </p>
                          <Tag type={payoutStatus.class} label={payoutStatus.label} size="small" />
                        </div>
                        <p className="spaces paragraph-xx-small neutral ellipsis-text">{formatSpaces(payout.spaces)}</p>
                      </td>
                      <td className="amount paragraph-x-small">{totalAmountCentsTooltip(payout.totalAmountCents)}</td>
                    </tr>

                    <tr
                      className={clsx('desktop-only', { 'last-row': lastRow })}
                      onClick={() => setLocation(`payouts/${payout.slug}`)}>
                      <td className="date-status row gap-xs">
                        <p className="date subtitle-x-small">{dayjs(payout.insertedAt).format('MMM DD, YYYY')} </p>
                        <Tag type={payoutStatus.class} label={payoutStatus.label} size="small" />
                      </td>
                      <td className="spaces subtitle-x-small">{formatSpaces(payout.spaces)}</td>
                      <td className="amount paragraph-x-small">{totalAmountCentsTooltip(payout.totalAmountCents)}</td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {!loadingPayouts && !hasPayouts && (
          <BlankState title="No payouts" message="Your payouts will appear here" emptyStateImage={payoutsEmptyState} />
        )}
      </div>
      <ErrorModal />
    </LoggedPageContainer>
  );
};

export default Payouts;
