import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import { mapStatusClass, refundReasonTranslator } from 'defaults/transaction';
import { currencyFormatter, dateFormat, hasPaymentPlanInstallments } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import ICONS from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import Modal from 'components/Modal';
import { OrderItemDetail } from 'components/ResponseDetail/OrderItemDetail';
import PaymentPlanTooltip from 'components/PaymentBlock/PaymentPlanTooltip';
import ProtectedComponent from 'components/ProtectedComponent';
import RefundModal from 'components/RefundModal';
import ReviewRequestModal from 'components/RefundModal/ReviewRequestModal';
import Tag from 'components/Tag';
import Tooltip from 'components/Tooltip';
import TransactionSummary, { SummaryItem } from 'components/Footer/TransactionSummary';
import { PageResponse, Response } from 'api/data/response/types';
import { RefundedOrderItem, Transaction } from 'api/data/transaction/types';
import './style.scss';

interface TransactionType {
  transactions: Transaction[];
  pageResponse: PageResponse | Response | undefined;
  pageTitle: string;
  pageId?: string;
  pageSpaceSlug?: string;
}

interface TransactionDetailType {
  transaction?: Transaction;
  transactions: Transaction[] | [];
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
  pageId?: string;
  pageSpaceSlug?: string;
  responseId: string;
  pageTitle: string;
  userName: string;
}

export const Transactions: FC<TransactionType> = ({ transactions, pageResponse, pageId, pageSpaceSlug, pageTitle }) => {
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [transaction, setTransactionDetail] = useState<Transaction>();

  const paymentTransactions = transactions.filter(tx => tx.type === 'PAYMENT');

  useEffect(() => {
    if (transactions.length && transaction?.id) {
      setTransactionDetail(transactions.filter(transactionItem => transactionItem.id === transaction.id)[0]);
    }
  }, [transactions, transaction?.id]);

  if (paymentTransactions.length === 0) {
    return null;
  }

  const netAmountCents = (pageResponse as PageResponse)?.totalCents || (pageResponse as Response)?.netAmountCents || 0;
  const respondent = (pageResponse as PageResponse)?.respondent || (pageResponse as Response)?.user;

  return (
    <div className="response-detail-wrapper">
      <div className="transactions">
        <div className="event">
          <div className="event-header">
            <p>
              <span>Total </span>
              <b>{currencyFormatter(netAmountCents)}</b>
            </p>
          </div>
          {paymentTransactions.map(transactionItem => {
            const recurrings: { [key: string]: string } = {};
            let hasPaymentPlan = false;
            transactionItem.invoice.orderItems.forEach(item => {
              if (item.recurring) {
                const recurr = item.recurring.toLocaleLowerCase();
                recurrings[recurr] = recurr;
              }

              if (hasPaymentPlanInstallments(item.variant.paymentPlanInstallments)) {
                hasPaymentPlan = true;
              }
            });

            const qtyRecurrings = Object.keys(recurrings).length;
            const showRecurring = qtyRecurrings > 1 || (qtyRecurrings === 1 && !recurrings.once);

            return (
              <div
                role="presentation"
                className={clsx('event-item', {
                  'selected-event': transactionItem.id === transaction?.id,
                })}
                key={transactionItem.id}
                onClick={() => {
                  setTransactionDetail(transactionItem);
                  setShowTransactionDetail(true);
                }}>
                {transactionItem.lastRefundAt ? (
                  <div>
                    <p className="date-format">{dateFormat(transactionItem.lastRefundAt)}</p>
                    <Tag
                      type="neutral"
                      label={`${
                        transactionItem.availableAmountCents === 0 ? 'Refunded ' : 'Partially refunded '
                      } ${currencyFormatter(transactionItem.refundedAmountCents)}`}
                    />
                  </div>
                ) : (
                  <div>
                    {transactionItem.status === 'PENDING' ? (
                      <p className="date-format">{dateFormat(transactionItem.pendingAt)}</p>
                    ) : (
                      <p className="date-format">{dateFormat(transactionItem.processedAt)}</p>
                    )}
                    <Tag
                      type={mapStatusClass[transactionItem.status]}
                      label={transactionItem.status.toLocaleLowerCase()}
                    />
                  </div>
                )}
                <div className="amount-status">
                  <div className="amount-recurring-box">
                    {showRecurring && (
                      <span className="event-recurring">
                        {transactionItem.invoice.occurrence && hasPaymentPlan ? (
                          <PaymentPlanTooltip />
                        ) : (
                          <Tooltip title={Object.values(recurrings).join(' ')} color="dark">
                            <span>{ICONS['recurring']}</span>
                          </Tooltip>
                        )}
                      </span>
                    )}
                    <span
                      className={clsx('event-amount', {
                        'invalid-amount': transactionItem?.status === 'FAILED',
                      })}>
                      {transactionItem.lastRefundAt
                        ? currencyFormatter(transactionItem.availableAmountCents)
                        : currencyFormatter(transactionItem.netAmountCents)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="details-wrapper">
          <CSSTransition
            timeout={200}
            in={showTransactionDetail}
            classNames="animation"
            unmountOnExit
            onExited={() => setTransactionDetail(undefined)}>
            <TransactionDetail
              setShowDetail={setShowTransactionDetail}
              transaction={transaction}
              transactions={transactions}
              responseId={pageResponse?.id || ''}
              pageId={pageId}
              pageSpaceSlug={pageSpaceSlug}
              pageTitle={pageTitle}
              userName={respondent.fullName || ''}
            />
          </CSSTransition>
        </div>
        <Modal
          header="Payment"
          className="response-detail-modal"
          handleOnCancel={() => setShowTransactionDetail(false)}
          visible={showTransactionDetail}
          fullScreenMobile>
          <TransactionDetail
            setShowDetail={setShowTransactionDetail}
            transaction={transaction}
            transactions={transactions}
            responseId={pageResponse?.id || ''}
            pageId={pageId}
            pageSpaceSlug={pageSpaceSlug}
            pageTitle={pageTitle}
            userName={respondent.fullName || ''}
          />
        </Modal>
      </div>
    </div>
  );
};

export const TransactionDetail: FC<TransactionDetailType> = ({
  pageId,
  pageSpaceSlug,
  responseId,
  transaction,
  setShowDetail,
  transactions,
  pageTitle,
  userName,
}) => {
  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  const refundTransactions = transactions.filter(
    tx => transaction && tx.sourceTransactionId === transaction.id && tx.type === 'REFUND',
  );

  const transactionStatus = transaction?.status.toLocaleLowerCase();
  const paymentPlacedAt = dayjs(
    transactionStatus === 'pending' ? transaction?.pendingAt : transaction?.processedAt,
  ).fromNow();

  return (
    <div className="details">
      <div className="header">
        <span className="first-capitalize">{transaction?.type.toLocaleLowerCase()}</span>
        <IconButton
          icon="close"
          onClick={() => {
            setShowDetail(false);
          }}
        />
      </div>
      <div className="actions">
        <h3 className={clsx({ 'invalid-amount': transaction?.status === 'FAILED' })}>
          {currencyFormatter(transaction?.availableAmountCents)}
        </h3>
        <div className="row space-between">
          {refundTransactions.length > 0 ? (
            <span>Still available</span>
          ) : (
            <div className="row">
              <Tag type={mapStatusClass[transaction?.status || '']} label={transactionStatus || ''} />
              <span className="transaction-date">
                {transaction?.status === 'PENDING'
                  ? dateFormat(transaction?.pendingAt)
                  : dateFormat(transaction?.processedAt)}
              </span>
            </div>
          )}
          <ReviewRequestModal
            pageTitle={pageTitle}
            requesterName={userName}
            amountCents={transaction?.pendingPayerRequest?.amountCents}
            transactionId={transaction?.id}
            showButton={!!transaction?.hasPendingPayerRequest}
            responseId={responseId}
            pageId={pageId}
            subscriptionId={transaction?.pendingPayerRequest?.subscriptionId}
            cancelationReason={transaction?.pendingPayerRequest?.cancelationReason}
            transaction={transaction}
          />
          <ProtectedComponent
            action="PAYMENT_REFUND"
            currentUserOrganizationRole={currentOrg?.currentUserRole}
            currentUserSpaceRole={currentSpace?.currentUserRole}>
            <RefundModal
              pageId={pageId}
              pageSpaceSlug={pageSpaceSlug}
              responseId={responseId}
              showButton={!transaction?.hasPendingPayerRequest}
              transaction={transaction}
              refundTransactions={refundTransactions}
            />
          </ProtectedComponent>
        </div>
      </div>
      <div className="horizontal-line"></div>
      {refundTransactions.map(refundTransaction => {
        return (
          <div className="transaction-detail" key={refundTransaction.id}>
            <span className="vertical-line"></span>
            <div>
              <span className={clsx('status-icon', transactionStatus)}>{ICONS['refunded']}</span>
              {refundTransaction?.processedAt && refundTransaction.refundReason ? (
                <span>
                  Refunded {dayjs(refundTransaction.processedAt).fromNow()},{' '}
                  {refundReasonTranslator[refundTransaction.refundReason]}
                </span>
              ) : null}
            </div>
            <div className="order-detail">
              {(refundTransaction.paymentMethodCardBrand || refundTransaction.paymentMethodBankName) &&
              refundTransaction.paymentMethodLast4 ? (
                <div className="row order-item">
                  <span>Total refunded to</span>
                  <div className="last-4 first-capitalize refund-card-detail">
                    {refundTransaction.paymentMethodCardBrand
                      ? ICONS[refundTransaction.paymentMethodCardBrand.toLocaleLowerCase()]
                      : refundTransaction.paymentMethodBankName}{' '}
                    •••• {refundTransaction.paymentMethodLast4}
                  </div>
                </div>
              ) : (
                refundTransaction.refund &&
                refundTransaction.refund.refundedOrderItems?.map((refundedOrderItem: RefundedOrderItem) => {
                  const orderItem = refundedOrderItem.orderItem;
                  const unitPrice = orderItem.unitPriceCents || 0;

                  return (
                    <div key={refundTransaction.id} className="order-item">
                      <div>
                        <div>
                          <span>{orderItem.blockTitle} </span>
                          <span className="order-item-title">{orderItem.variantTitle}</span>
                        </div>
                        <div className="order-item-variant-quantity">Qty {refundedOrderItem.quantity}</div>
                      </div>
                      <div className="amount-recurring-box">
                        <strong>{currencyFormatter(refundedOrderItem.quantity * unitPrice)}</strong>
                      </div>
                    </div>
                  );
                })
              )}
              <div className="horizontal-line"></div>
              <div className="transaction-summary">
                <SummaryItem
                  label="Total refunded by organization"
                  amountFormatted={currencyFormatter(refundTransaction.netAmountCents)}
                />
                <div className="horizontal-line"></div>
                <SummaryItem
                  label="Credit refunded by Omella"
                  amountFormatted={currencyFormatter(refundTransaction.feeAmountCents)}
                />
                <div className="horizontal-line"></div>
                <SummaryItem
                  label="Total refunded to payer"
                  amountFormatted={currencyFormatter(refundTransaction.totalAmountCents)}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="transaction-detail">
        <div>
          <span className={clsx('status-icon first-capitalize', transactionStatus)}>
            {ICONS[transactionStatus || '']}
          </span>
          {transaction?.processedAt || transactionStatus === 'pending' ? (
            <span>Payment placed {transaction?.processedAt || transaction?.pendingAt ? paymentPlacedAt : null}</span>
          ) : null}
        </div>
        <div className="order-detail">
          {transaction?.invoice.orderItems.map(orderItem => (
            <OrderItemDetail key={orderItem.id} orderItem={orderItem} occurrence={transaction.invoice.occurrence} />
          ))}
          <div className="horizontal-line"></div>
          <TransactionSummary
            subtotalAmountCents={transaction?.invoice.subtotalCents}
            totalAmountCents={transaction?.netAmountCents}
            isPayerView={false}
            processingFee={
              transaction?.processingFeePayer === 'organization' ? transaction?.processingFeeAmountCents : 0
            }
            showPaymentMethod
            platformFeeAmountCents={transaction?.platformFeeAmountCents}
            recurringFeeAmountCents={transaction?.recurringFeeAmountCents}
            paymentMethodType={transaction?.paymentMethodType || ''}
            bankName={transaction?.paymentMethodBankName || ''}
            last4={transaction?.paymentMethodLast4 || ''}
            cardBrand={transaction?.paymentMethodCardBrand || ''}
          />
        </div>
      </div>
    </div>
  );
};
