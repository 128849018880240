import React, { useState } from 'react';
import { useRoute, Link, useLocation } from 'wouter';
import { useQuery, useMutation } from '@apollo/client';
import * as PayoutsQuery from 'graphql/payout.graphql';
import { currencyFormatter } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import ICONS from 'components/Icons';
import LoggedPageContainer from 'components/LoggedPageContainer';
import { PayoutButton } from '..';
import RecipientDropDown from '~/pages/Payouts/AvailableToPayOut/RecipientDropDown';
import Summary from '../Details/Summary';
import Tag from 'components/Tag';
import Text from 'components/Input';
import { PayoutsBalanceByOrganization, PayoutDetails } from 'api/data/payout/types';
import './style.scss';

const AvailableToPayOut = () => {
  const [, params] = useRoute('/:currentSpace/payouts/available-to-pay-out');
  const [, setLocation] = useLocation();
  const { currentOrg } = useCurrentOrganization();

  const [payout, setPayout] = useState({
    recipient: '',
    name: currentOrg?.name,
    description: '',
  });

  const { loading: loadingBalance, data: balanceData } = useQuery<PayoutsBalanceByOrganization>(
    PayoutsQuery.PayoutsBalanceByOrganization,
    {
      variables: {
        organizationId: currentOrg?.id,
      },
      skip: !currentOrg?.id,
    },
  );
  const { data: pendingTx } = useQuery<{ pendingPayoutTransactions: PayoutDetails }>(
    PayoutsQuery.PendingPayoutTransactions,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [initiatePayout, { loading }] = useMutation<{ initiatePayout: PayoutDetails }>(PayoutsQuery.InitiatePayout);

  const handleOnChange = (field: string, value: string) => {
    setPayout({
      ...payout,
      [field]: value,
    });
  };

  const balance = balanceData?.payoutsBalanceByOrganization;
  const accountRestricted = currentOrg?.status === 'RESTRICTED' && currentOrg.provider === 'stripe';
  const hasAvailableAmountCents = !!balance?.availableAmountCents && balance?.availableAmountCents > 0;
  const availableToPayOut =
    !accountRestricted &&
    !!hasAvailableAmountCents &&
    !!payout.recipient &&
    !!payout.name &&
    !!payout.description &&
    !loading;

  const handlePayout = async () => {
    const { data } = await initiatePayout({
      variables: {
        payout: {
          ...payout,
          amountCents: balance?.availableAmountCents,
        },
      },
    });
    setLocation(`/${params?.currentSpace}/payouts/${data?.initiatePayout.slug}?initiated=true`);
  };

  return (
    <LoggedPageContainer
      className="payouts available-pay-out"
      organizationStatus={currentOrg?.status}
      headerChildren={
        <div className="row align-center second-header">
          <Link className="row align-center subtitle-x-small" to={`/${params?.currentSpace}/payouts`}>
            {ICONS['wallet']} Payouts
          </Link>
          <span className="row align-center subtitle-x-small arrow-right">
            {ICONS['arrow_right']} Available to payout
          </span>
        </div>
      }>
      <div className="max-width-desktop">
        <div className="row space-between align-center payout-header">
          <div>
            <p className="subtitle-xx-small">Available to payout</p>
            {loadingBalance ? (
              <>Loading...</>
            ) : (
              <span className="row align-center heading-small">
                {currencyFormatter(balance?.availableAmountCents)}
                {currentOrg?.payoutSchedule && currentOrg?.payoutSchedule !== 'MANUAL' && (
                  <Tag
                    label={`Scheduled ${currentOrg?.payoutSchedule.toLocaleLowerCase()}`}
                    type="neutral"
                    capitalize
                  />
                )}
              </span>
            )}
          </div>
          {currentOrg?.provider === 'checkout' && (
            <PayoutButton
              accountRestricted={accountRestricted}
              hasAvailableAmountCents={hasAvailableAmountCents}
              availableToPayOut={availableToPayOut}
              handleOnClick={() => void handlePayout()}
              label={loading ? 'Loading...' : `Pay out ${currencyFormatter(balance?.availableAmountCents)}`}
            />
          )}
        </div>
        {currentOrg?.provider === 'checkout' && (
          <div className="row space-between payout-detail">
            <div>
              <RecipientDropDown
                selectedRecipientEmail={payout.recipient}
                onChange={id => handleOnChange('recipient', id)}
              />
            </div>
            <div>
              <Text
                name="business-name"
                label="To the order of"
                value={payout.name}
                onChange={({ target }) => handleOnChange('name', target.value)}
                rules={{ required: true }}
              />
            </div>
            <div>
              <Text
                name="memo"
                label="Memo"
                value={payout.description}
                onChange={({ target }) => handleOnChange('description', target.value)}
                rules={{ required: true }}
              />
            </div>
          </div>
        )}
        <div className="summary">
          {pendingTx?.pendingPayoutTransactions.spaces && (
            <Summary data={pendingTx?.pendingPayoutTransactions.spaces || {}} />
          )}
        </div>
      </div>
    </LoggedPageContainer>
  );
};

export default AvailableToPayOut;
